





































































import { Vue, Component, PropSync } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Home extends Vue {
  @PropSync('conf') syncConf!: any;
}
